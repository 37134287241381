import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Register.css';

const Register = ({ language }) => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [fieldErrors, setFieldErrors] = useState({});
    const [showTips, setShowTips] = useState(false);
    const [passwordMatchError, setPasswordMatchError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccessMessage('');
        setErrorMessage('');
        setFieldErrors({});

        if (!password || password !== confirmPassword) {
            setPasswordMatchError(language === 'pt' ? 'Senhas não coincidem.' : language === 'es' ? 'Las contraseñas no coinciden.' : 'Passwords do not match.');
            return;
        }

        try {
            const response = await axios.post('https://ba2add21-2f50-4d9e-84c9-1c06ee3cb5e3-00-1bm00jl761pt7.worf.replit.dev:8000/auth/register', {
                username,
                email,
                password
            });

            if (response.data.message === 'Usuário registrado com sucesso!') {
                setSuccessMessage(language === 'pt' ? 'Parabéns, seu usuário foi registrado com sucesso!' : language === 'es' ? '¡Felicidades, su usuario ha sido registrado con éxito!' : 'Congratulations, your user has been successfully registered!');
                
                setTimeout(() => {
                    navigate('/login');
                }, 2000);  // Redireciona para a página de login após 2 segundos
            } else {
                setErrorMessage(language === 'pt' ? 'Erro ao registrar' : language === 'es' ? 'Error al registrar' : 'Error registering');
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.detail) {
                setErrorMessage(error.response.data.detail);
                if (error.response.data.errors) {
                    setFieldErrors(error.response.data.errors);
                }
            } else {
                setErrorMessage(language === 'pt' ? 'Houve um erro ao registrar!' : language === 'es' ? '¡Hubo un error al registrar!' : 'There was an error registering!');
            }
        }
    };

    const toggleTips = () => {
        setShowTips(!showTips);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordMatchError('');
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        if (e.target.value !== password) {
            setPasswordMatchError(language === 'pt' ? 'Senhas não coincidem.' : language === 'es' ? 'Las contraseñas no coinciden.' : 'Passwords do not match.');
        } else {
            setPasswordMatchError('');
        }
    };

    return (
        <div className="register-page">
            <div className="register-container">
                <button className="back-button" onClick={() => navigate('/login')}>
                    <i className="fas fa-arrow-left"></i>
                </button>
                <h2>{language === 'pt' ? 'Registrar' : language === 'es' ? 'Registrar' : 'Register'}</h2>
                {successMessage && <p className="success-message" style={{ color: 'green' }}>{successMessage}</p>}
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <label>{language === 'pt' ? 'Usuário' : language === 'es' ? 'Usuario' : 'Username'}</label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                        {fieldErrors.username && <p className="error-message">{fieldErrors.username}</p>}
                    </div>
                    <div className="input-group">
                        <label>{language === 'pt' ? 'Email' : language === 'es' ? 'Correo electrónico' : 'Email'}</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        {fieldErrors.email && <p className="error-message">{fieldErrors.email}</p>}
                    </div>
                    <div className="input-group">
                        <label>{language === 'pt' ? 'Senha' : language === 'es' ? 'Contraseña' : 'Password'}</label>
                        <input
                            type="password"
                            value={password}
                            onChange={handlePasswordChange}
                            required
                        />
                        {fieldErrors.password && <p className="error-message">{fieldErrors.password}</p>}
                    </div>
                    <div className="input-group">
                        <label>{language === 'pt' ? 'Confirmar Senha' : language === 'es' ? 'Confirmar contraseña' : 'Confirm Password'}</label>
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            required
                        />
                        {passwordMatchError && <p className="error-message">{passwordMatchError}</p>}
                    </div>
                    <button type="submit" className="register-button" disabled={password !== confirmPassword || password.length < 8}>
                        {language === 'pt' ? 'Registrar' : language === 'es' ? 'Registrar' : 'Register'}
                    </button>
                </form>
                <p className="tips-toggle" onClick={toggleTips}>
                    {language === 'pt' ? 'Clique aqui para dicas de como fazer um bom registro' : language === 'es' ? 'Haga clic aquí para obtener consejos sobre cómo hacer un buen registro' : 'Click here for tips on how to make a good registration'}
                </p>
                {showTips && (
                    <div className="tips-content">
                        <p><strong>{language === 'pt' ? 'Dicas de Registro:' : language === 'es' ? 'Consejos de Registro:' : 'Registration Tips:'}</strong></p>
                        <ul>
                            <li>{language === 'pt' ? 'O nome de usuário deve ter entre 3 e 20 caracteres, além de conter letras, números e underscores.' : language === 'es' ? 'El nombre de usuario debe tener entre 3 y 20 caracteres e incluir letras, números y guiones bajos.' : 'Username should be between 3 and 20 characters long, and include letters, numbers, and underscores.'}</li>
                            <li>{language === 'pt' ? 'Use um endereço de email válido.' : language === 'es' ? 'Utilice una dirección de correo electrónico válida.' : 'Use a valid email address.'}</li>
                            <li>{language === 'pt' ? 'A senha deve ter pelo menos 8 caracteres, incluindo letras, números e um caractere especial (como o "@", dentre outros).' : language === 'es' ? 'La contraseña debe tener al menos 8 caracteres, incluyendo letras, números y un carácter especial (como "@", entre otros).' : 'Password should be at least 8 characters long, including letters, numbers, and a special character (like "@", among others).'}</li>
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Register;
