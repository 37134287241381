import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Ocorrencias.css';

const Ocorrencias = ({ language }) => {
    const [currentImage, setCurrentImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [feedbacks, setFeedbacks] = useState([]);
    const [idCliente, setIdCliente] = useState(null);

    // Obtém o ID do cliente diretamente do localStorage após o login
    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser && storedUser.id_cliente) {
            setIdCliente(storedUser.id_cliente);
        }
    }, []);

    // Buscar ocorrências para feedback após o ID do usuário ser obtido
    useEffect(() => {
        if (idCliente !== null) {
            fetchFeedbacks();
        }
    }, [idCliente]);

    const fetchFeedbacks = async () => {
        setLoading(true);
        try {
            const response = await axios.post('https://ba2add21-2f50-4d9e-84c9-1c06ee3cb5e3-00-1bm00jl761pt7.worf.replit.dev:8000/feedback/get_occurrences', {
                id_user: idCliente,
                tipo: 2
            });
            setFeedbacks(response.data.images);
            if (response.data.images.length > 0) {
                // Exibir a primeira imagem em base64
                setCurrentImage({
                    id: response.data.images[0][0],  // Captura o ID da imagem
                    base64: response.data.images[0][1]  // Captura o base64 da imagem
                });
                setCurrentIndex(0);
            } else {
                setCurrentImage(null); // Não há mais imagens
            }
            setLoading(false);
        } catch (error) {
            console.error(language === 'pt' ? 'Erro ao buscar ocorrências:' : language === 'es' ? 'Error al buscar ocurrencias:' : 'Error fetching occurrences:', error);
            setLoading(false);
        }
    };

    const handleFeedback = async (feedback) => {
        if (!currentImage) return;

        setLoading(true); // Mostrar "Carregando imagem..." após o feedback

        try {
            const response = await axios.post('https://ba2add21-2f50-4d9e-84c9-1c06ee3cb5e3-00-1bm00jl761pt7.worf.replit.dev:8000/feedback/post', {
                image_id: currentImage.id,  // Envia o ID da imagem ao backend
                correct_classification: feedback
            });

            if (response.status === 200) {
                // Após o feedback, fazer nova requisição para obter a lista atualizada
                await fetchFeedbacks();
            } else {
                console.error('Error submitting feedback:', response.data);
                setLoading(false); // Parar de mostrar "Carregando" em caso de erro
            }
        } catch (error) {
            console.error(language === 'pt' ? 'Erro ao enviar feedback:' : language === 'es' ? 'Error al enviar comentarios:' : 'Error sending feedback:', error);
            setLoading(false); // Parar de mostrar "Carregando" em caso de erro
        }
    };

    const renderImage = () => {
        if (loading) {
            return <p>{language === 'pt' ? 'Carregando imagem...' : language === 'es' ? 'Cargando imagen...' : 'Loading image...'}</p>;
        }

        if (currentImage && currentImage.base64) {
            return <img src={`data:image/jpeg;base64,${currentImage.base64}`} alt={`Imagem ${currentImage.id}`} />;
        }

        return <p>{language === 'pt' ? 'Nenhuma ocorrência encontrada.' : language === 'es' ? 'No se encontraron ocurrencias.' : 'No occurrences found.'}</p>;
    };

    return (
        <div className="ocorrencias-page">
            <h1>{language === 'pt' ? 'Ocorrências' : language === 'es' ? 'Ocurrencias' : 'Occurrences'}</h1>
            {renderImage()}
            {!loading && currentImage && (
                <div>
                    <button onClick={() => handleFeedback('positivo')}>
                        <span role="img" aria-label="thumbs up">👍</span> {language === 'pt' ? '(Obrigado pelo aviso)' : language === 'es' ? '(Gracias por el aviso)' : '(Thanks for the notice)'}
                    </button>
                    <button onClick={() => handleFeedback('Alarme Falso')}>
                        <span role="img" aria-label="thumbs down">👎</span> {language === 'pt' ? '(Alarme Falso)' : language === 'es' ? '(Falsa Alarma)' : '(False Alarm)'}
                    </button>
                </div>
            )}
        </div>
    );
};

export default Ocorrencias;
